import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { notifyError, notifyTopRight } from "../common/Toaster";
import addPhoto, { createEvent } from "../../services/User/UserService";
import AWS from "aws-sdk";

export default function AddEvent({ show, table, onHide }) {
  const albumName = "tixscape";
  const [uploadImg, setUploadImg] = useState("");

  const s3 = new AWS.S3();

  const initialValues = {
    image: "",
    name: "",
    url: "",
    type: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Name is required"),
    url: Yup.string()
      .trim()
      .required("Image URL is required")
      .url("Enter a valid URL"),
    type: Yup.string().required("Category is required"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    const imgLocation = await handleImageUpload();
    if (imgLocation) {
      values.image = imgLocation;
    }
    try {
      const response = await createEvent(values);
      notifyTopRight(response?.data?.message);
      resetForm();
      table();
      onHide();
    } catch (error) {
      notifyError(error.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  // const handleImageUpload = async (e, setFieldValue) => {
  //   const selectedFile = e.target.files[0];
  //   if (selectedFile) {
  //     try {
  //       const response = await addPhoto(selectedFile, albumName);
  //       setFieldValue("image", response.Location);
  //       notifyTopRight("Image uploaded successfully!");
  //     } catch (error) {
  //       console.error("Image upload error:", error);
  //       notifyError("Image upload failed. Please try again.");
  //     }
  //   }
  // };

  function getContentTypeByFile(fileName) {
    const extension = fileName.split(".").pop();
    switch (extension) {
      case "mp4":
        return "video/mp4";
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "m4a":
        return "audio/mp4";
      default:
        return "application/octet-stream";
    }
  }
  const handleImageUpload = async () => {
    if (!uploadImg) {
      console.error("No file selected");
      return null;
    }

    const contentType = getContentTypeByFile(uploadImg?.name);

    const params = {
      Bucket: process.env.REACT_APP_PUBLIC_S3_BUCKET_NAME,
      Key: uploadImg?.name,
      Body: uploadImg,
      ContentType: contentType,
    };

    try {
      const data = await s3.upload(params).promise();
      return data?.Location;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, errors }) => {
          return (
            <Form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Details</h4>
              </div>
              <div className="modal-body">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    name="image"
                    // onChange={(e) => handleImageUpload(e, setFieldValue)}
                    onChange={(e) => setUploadImg(e.target.files[0])}
                  />
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="text-danger fs-12"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Image URL</label>
                  <Field type="text" className="form-control" name="url" />
                  <ErrorMessage
                    name="url"
                    component="div"
                    className="text-danger fs-12"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Name</label>
                  <Field type="text" className="form-control" name="name" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger fs-12"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Category</label>
                  <Field as="select" className="form-control" name="type">
                    <option hidden value="">
                      Select
                    </option>
                    <option value="sports">Sports</option>
                    <option value="concert">Concert</option>
                    <option value="theater">Theater</option>
                    <option value="comedy">Comedy</option>
                    <option value="other">Other</option>
                  </Field>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="text-danger fs-12"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-secondary"
                >
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
